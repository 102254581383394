import { SRLWrapper } from "simple-react-lightbox";

import p9 from "../src/Images/p9.jpeg";

import p10 from "../src/Images/p10.jpg";
import p11 from "../src/Images/p11.jpg";
import p12 from "../src/Images/p12.jpg";
import p17 from "../src/Images/p17.jpeg";
import p18 from "../src/Images/p18.jpeg";
import p19 from "../src/Images/p19.jpeg";
import p20 from "../src/Images/p20.jpeg";
import p21 from "../src/Images/p21.jpeg";
import p23 from "../src/Images/p23.jpeg";
import p5 from "../src/Images/p5.jpg";
import p6 from "../src/Images/p6.jpg";
import p7 from "../src/Images/p7.jpg";
import p8 from "../src/Images/p8.jpg";

import p13 from "../src/Images/p13.jpg";
import p14 from "../src/Images/p14.jpg";
import p15 from "../src/Images/p15.jpeg";
import p16 from "../src/Images/p16.jpg";
import { useEffect, useState } from "react";

const Gallery = () => {
  const slides = [
    { title: p17, description: "View Gallery" },
    { title: p18, description: "View Gallery" },
    { title: p19, description: "View Gallery" },
    { title: p20, description: "View Gallery" },
    { title: p21, description: "View Gallery" },
    { title: p23, description: "View Gallery" },
    { title: p5, description: "View Gallery" },
    { title: p6, description: "View Gallery" },
    { title: p7, description: "View Gallery" },
    { title: p8, description: "View Gallery" },

    { title: p9, description: "View Gallery" },

    { title: p10, description: "View Gallery" },
    { title: p11, description: "View Gallery" },
    { title: p12, description: "View Gallery" },

    { title: p13, description: "View Gallery" },
    { title: p14, description: "View Gallery" },
    { title: p15, description: "View Gallery" },
    { title: p16, description: "View Gallery" },
  ];
  const [photos, setPhotos] = useState([]);

  const getImages = async () => {
    const res = await fetch(
      "https://cms.maitretech.com/ncfa/items/photo_gallery?fields=*.*"
    );
    const data = await res.json();
    console.log("img response", data);
    setPhotos(data.data);
  };
  useEffect(() => {
    getImages();
  }, []);
  return (
    <div className="container-fluid page-container">
      <div className="row mt-5 mb-5">
        <div className="col-xl-12  col-lg-12 col-md-12  col-sm-12 Coursesss">
          <h1>Photos</h1>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-1"></div>
        <div className="col-lg-10  Activities_content">
          <h2>
            Art is a means of showing your expressions, emotions and thoughts.
            Imagination has wings, moreover, when passion for arts gets a hand
            of right guidance, then the resulting artwork will be a great one.
            That is, when artistic attitude blends with innocent creativity, it
            gives a magnificent artwork such as these following paintings.
          </h2>
        </div>
        <div className="col-lg-1"></div>
      </div>
      <br />
      <br />
      <br />
      <div className="row">
        <SRLWrapper>
          <div className="mb-3 row">
            <div className="col-sm-12 justify-content-center">
              {photos.map((item, i) => {
                console.log("photos", item);
                return (
                  <div key={i} className="col-sm-3 ">
                    <img
                      src={item?.image.data.full_url}
                      className="mt-3"
                      alt=""
                      style={{
                        height: "200px",
                        width: "100%",
                        border: "7px solid #17a2b8",
                      }}
                    />
                  </div>
                );
              })}
            </div>
          </div>
        </SRLWrapper>
      </div>

      <br />
      <br />
    </div>
  );
};
export default Gallery;
