import React from "react";
import { useState, useEffect } from "react";

import { useHistory } from "react-router-dom";
import FullBlog from "./FullBlog";

function Blog() {
  const [blog, setBlog] = useState([]);
  const history = useHistory();

  const getBlog = async () => {
    const res = await fetch(
      "https://cms.maitretech.com/ncfa/items/blog?fields=*.*"
    );
    const data = await res.json();
    console.log("setBlog", data);
    setBlog(data.data);
  };
  useEffect(() => {
    getBlog();
  }, []);

  const handleClick = (id) => {
    console.log("id", id);
    history.push(`/FullBlog/${id}`);
  };
  return (
    <>
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "flex-start",
          gap: 20,
          flexWrap: "wrap",
          padding: 50,
        }}
      >
        {blog?.map((item, index) => {
          console.log("item", item);
          return (
            <div
              key={index}
              class="card"
              style={{ width: "40rem", flexWrap: "wrap" }}
            >
              <img
                src={item?.blog_img.data?.full_url}
                class="card-img-top"
                alt="..."
                height={200}
              />
              <div class="card-body">
                <h2 class="card-title">{item.title}</h2>
                <p class="card-text" style={{ fontSize: 15 }}>
                  {item?.content}
                </p>

                <button
                  type="button"
                  className="btn btn-outline12"
                  onClick={() => {
                    handleClick(item?.id);
                  }}
                >
                  Full Blog
                </button>
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
}

export default Blog;
