import React from "react";
import CallIcon from "@material-ui/icons/Call";
import NCFA3 from "../src/Images/NCFA3.gif";

const Header = () => {
  return (
    <div className="container-fluid">
      <div className=" row header_s">
        <div className="col-xl-6 col-lg-5 col-md-12 col-sm-12 gmail ">
          <h1 className=" ml-5 pt-2 ">ncfabhopal@gmail.com</h1>
        </div>
        <div className="col-xl-2"></div>
        <div className="col-xl-3  col-lg-5 col-md-12 col-sm-12 m-0 p-0">
          <div className="m-0 p-0" style={{ display: "flex" }}>
            <CallIcon className=" callicons mt-3" style={{ height: "22px" }} />
            <h3 className=" mt-3 ">9425183888 </h3>
            <h3 className="  mt-3 ml-3 ">7691901888 </h3>
            <h3 className="  mt-3 ml-3 ">7691901666</h3>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-xl-2 col-lg-2 col-md-4 col-sm-4">
          <center>
            <img
              src={NCFA3}
              className=""
              style={{ height: "100px" }}
              alt="no_img"
            />
          </center>
        </div>
        <div
          className=" col-xl-3  col-lg-2 col-md-6  col-sm-7"
          style={{ textAlign: "center" }}
        >
          <h2 className="mt-5">Welcome to Narmada College of Fine Arts</h2>
        </div>
        <div className=" col-xl-7 col-lg-7 col-md-12 col-sm-12 hader_menu1 mt-3">
          <nav className="navbar navbar-expand-md navbar-light bg-light mb-3  justify-content-center">
            <div>
              <button
                type="button"
                className="navbar-toggler"
                data-toggle="collapse"
                data-target="#navbarCollapse"
              >
                <span className="navbar-toggler-icon"></span>
              </button>
              <div className="collapse navbar-collapse" id="navbarCollapse">
                <div className="navbar-nav">
                  <ul className="ulli ">
                    <li>
                      <a href="/">Home</a>
                    </li>
                    <li>
                      <a
                        href="AboutUs"
                        className="btn  dropdown-toggle"
                        type="button"
                        id="dropdownMenuButton"
                        data-toggle="dropdown"
                        // aria-haspopup="true"
                        aria-expanded="false"
                      >
                        About us
                      </a>
                      <div className="dropdown">
                        <div
                          className="dropdown-menu"
                          aria-labelledby="dropdownMenuButton"
                        >
                          <a className="dropdown-item  dropdown1" href="About">
                            About
                          </a>
                          <a
                            className="dropdown-item  dropdown1"
                            href="AboutUs"
                          >
                            Director message
                          </a>
                          <a
                            className="dropdown-item  dropdown1"
                            href="Facilities"
                          >
                            Career Options
                          </a>
                        </div>
                      </div>
                    </li>

                    <li>
                      <a href="/Courses">Courses</a>
                    </li>
                    <li>
                      <a href="/Blog">Blog</a>
                    </li>

                    <li>
                      <a
                        href="Photos"
                        className="btn  dropdown-toggle"
                        type="button"
                        id="dropdownMenuButton"
                        data-toggle="dropdown"
                        // aria-haspopup="true"
                        aria-expanded="false"
                      >
                        Media
                      </a>
                      <div className="dropdown">
                        <div
                          className="dropdown-menu"
                          aria-labelledby="dropdownMenuButton"
                        >
                          <a
                            className="dropdown-item  dropdown1"
                            href="/Photos"
                          >
                            Photos
                          </a>
                          <a
                            className="dropdown-item  dropdown1"
                            href="/Videos"
                          >
                            Videos
                          </a>
                        </div>
                      </div>
                    </li>

                    <li>
                      <a href="/Contact">Contact </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </nav>
        </div>
      </div>
    </div>
  );
};
export default Header;
