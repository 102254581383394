import React, { useEffect, useState } from "react";

function Videos() {
  const [videoLink, setVideoLink] = useState([]);

  const getVideos = async () => {
    const res = await fetch(
      "https://cms.maitretech.com/ncfa/items/videos?fields=*.*"
    );
    const data = await res.json();
    console.log("Videos", data);
    setVideoLink(data.data);
  };
  useEffect(() => {
    getVideos();
  }, []);
  return (
    <>
      <div
        class="sc-ikkyvV hqOnki"
        style={{
          display: "flex",
          justifyContent: "flex-start",
          gap: 10,
          alignItems: "center",
          flexWrap: "wrap",
          padding: 30,
        }}
      >
        {videoLink?.map((video, index) => {
          return (
            <div key={index} class="sc-dAbbbq eqVWZu">
              <iframe width="420" height="200" src={video?.link}></iframe>
            </div>
          );
        })}
      </div>
    </>
  );
}

export default Videos;
