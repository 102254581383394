import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
const Home = () => {
  const [sliderImg, setSliderImg] = useState([]);
  const [studentWorkImg, setStudentWorkImg] = useState([]);
  const [ourCoursesImg, setOurCoursesImg] = useState([]);
  const getSliderImages = async () => {
    const res = await fetch(
      "https://cms.maitretech.com/ncfa/items/slider?fields=*.*"
    );
    const data = await res.json();
    console.log("img response", data);
    setSliderImg(data.data);
  };
  useEffect(() => {
    getSliderImages();
  }, []);
  const getStudentWorkImages = async () => {
    const res = await fetch(
      "https://cms.maitretech.com/ncfa/items/student_work?fields=*.*"
    );
    const data = await res.json();
    console.log("img response", data);
    setStudentWorkImg(data.data);
  };
  useEffect(() => {
    getStudentWorkImages();
  }, []);

  const getOurCoursesImg = async () => {
    let courses = [];
    const res = await fetch(
      "https://cms.maitretech.com/ncfa/items/our_course_img?fields=*.*"
    );
    const data = await res.json();
    console.log("our courses", data);
    for (let i = 0; i < 3; i++) {
      courses.push(data.data[i]);
    }

    setOurCoursesImg(courses);
    console.log("ajg", courses);
  };
  useEffect(() => {
    getOurCoursesImg();
  }, []);

  return (
    <div className="container-fluid">
      <div className="row ">
        {/* <div className="col-lg-0 col-md-2 col-sm-3"></div> */}
        <div className="col-lg-12 col-md-12 col-sm-12 ">
          <div id="myCarousel" className="carousel slide" data-ride="carousel">
            <ol className="carousel-indicators">
              <li
                data-target="#myCarousel"
                data-slide-to="0"
                className="active"
              ></li>
              <li data-target="#myCarousel" data-slide-to="1"></li>
              <li data-target="#myCarousel" data-slide-to="2"></li>
              <li data-target="#myCarousel" data-slide-to="3"></li>
            </ol>
            <div className="carousel-inner carouseldone">
              <div className="item active sam himg1" style={{ height: "70vh" }}>
                <img
                  className="img-responsive "
                  src={sliderImg[0]?.slider_img?.data?.full_url}
                  alt="Los Angeles"
                />
              </div>

              <div className="item himg2" style={{ height: "70vh" }}>
                <img
                  src={sliderImg[1]?.slider_img?.data?.full_url}
                  alt="Chicago"
                />
              </div>
              <div className="item himg3" style={{ height: "70vh" }}>
                <img
                  src={sliderImg[2]?.slider_img?.data?.full_url}
                  alt="New York"
                />
              </div>
              <div className="item himg4" style={{ height: "70vh" }}>
                <img
                  src={sliderImg[3]?.slider_img?.data?.full_url}
                  alt="New York"
                />
              </div>
            </div>
            <a
              className="left carousel-control"
              href="#myCarousel"
              data-slide="prev"
            >
              <span className="glyphicon glyphicon-chevron-left"></span>
              <span className="sr-only">Previous</span>
            </a>
            <a
              className="right carousel-control"
              href="#myCarousel"
              data-slide="next"
            >
              <span className="glyphicon glyphicon-chevron-right"></span>
              <span className="sr-only">Next</span>
            </a>
          </div>
        </div>
      </div>
      <br />
      <br />
      <br />
      <br />
      <br />
      <div className="row">
        <div className="col-sm-12 sam12">
          <h1 style={{ textAlign: "center" }} className="fineArt_css">
            Welcome to Narmada College of Fine Arts
          </h1>
          <br />
          <br />
          <p className="col-xl-1"></p>

          <p className="col-lg-1"></p>
          <div className="mt-3 col-sm-12">
            <h2 style={{ color: "", textAlign: "center" }}>
              Narmada College of Fine Arts is a center for education in art and
              designing, fostering the excellence of emerging and established
              artists and advancing art to create a more human world. Narmada
              College of Fine Arts was established by Mrs. Dr. Aparna Laad in
              Bhopal (M.P) on July 2005, which is affiliated to Raja Mansingh
              Music and Arts University (Gwalior). Our aim is to provide
              education and training to students on a wide spectrum of Fine Arts
              and Designing. We also encouraged artistic activities so that art
              students could become practically mature along with academic
              knowledge.
            </h2>
            <br />
            <br />

            <p className="col-xl-1"></p>
          </div>
        </div>
      </div>
      <div className="mb-5 row">
        <div className="col-xl-12 col-lg-12 col-md-6 col-sm-12 Coursesss">
          <h1> Our Courses</h1>
        </div>
      </div>
      <div className="row imnb">
        <div className="col-xl-1"></div>
        {ourCoursesImg?.map((item, index) => {
          return (
            <div
              key={index}
              className="col-xl-3 col-lg-4 col-md-4 col-sm-12 mgn12 "
            >
              <div
                className="pac-col4 thumbnail "
                style={{ textAlign: "center" }}
              >
                <img
                  src={item?.course_img?.data?.full_url}
                  className="img-responsive display_none "
                  style={{ width: "100%", height: "180px" }}
                  alt="no-img"
                  id="display_none"
                />
                <h3 className="art_name_btn mt-3 d-flex justify-content-center">
                  <a href="#/">{item?.title}</a>
                </h3>
                <div
                  class="dropdown11 mb-3 d-flex justify-content-center "
                  style={{ width: "100%" }}
                >
                  <button type="button" className="btn btn-outline12">
                    <a href="#/ " className="btn-outline2">
                      Read More
                    </a>
                  </button>
                  <div class="dropdown-content1">
                    <p className="pt-3">B.F.A 4 yrs </p>
                    <p>BA (Fine Arts) 3 yrs</p>
                    <p>M.F.A. (Painting) 2 yrs</p>
                    <p>M.A. (Painting) regular 2 yrs</p>
                    <p>M.A. (Painting) private 2 yrs</p>
                  </div>
                </div>
              </div>
            </div>
          );
        })}

        <div className="col-xl-1"></div>
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Link to="/Courses">
            <button type="button" className="btn btn-outline12">
              View All
            </button>
          </Link>
        </div>
      </div>
      <div className="mt-5 mb-5 row">
        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 Coursesss">
          <h1> Students Work</h1>
        </div>
      </div>

      <div className="p-0 m-0 row">
        {studentWorkImg?.map((item, index) => {
          return (
            <div
              id={index}
              className="mt-2 col-xl-3 col-lg-3 col-md-3 col-sm-3 mgn"
            >
              <img
                src={item?.img?.data?.full_url}
                className="img-responsive imgs1"
                alt="no-img"
              />
            </div>
          );
        })}
      </div>
      <div className="row">
        <div className="col-sm-12" style={{ textAlign: "center" }}>
          <button type="button" className="btn btn-outline">
            <a href="Photos" className="btn-outline2">
              More Work
            </a>
          </button>
        </div>
      </div>
      <br />
      <br />
    </div>
  );
};
export default Home;
