import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { BrowserRouter } from "react-router-dom";

import SimpleReactLightbox from 'simple-react-lightbox'


ReactDOM.render(

  <SimpleReactLightbox> 
    <BrowserRouter>
      <App />
    </BrowserRouter> 
  </SimpleReactLightbox>,
  document.getElementById('root')
);


