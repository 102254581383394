import React, { useEffect, useState } from "react";
import artboard from "../src/Images/bgimg.jpg";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { Link } from "@material-ui/core";
function FullBlog(props) {
  console.log("prop", props.id);
  const { id } = useParams();
  const [filterBlog, setFilterBlog] = useState([]);

  const filterMyBlog = (array) => {
    const filterData = array?.filter((data) => data.id == id);
    console.log("filterData", filterData);
    setFilterBlog(filterData);
  };
  const getFullBlog = async () => {
    const response = await fetch(
      `https://cms.maitretech.com/ncfa/items/blog?fields=*.*`
    );
    const { data } = await response.json();
    console.log("full Blog", data);
    filterMyBlog(data);
  };
  useEffect(() => {
    getFullBlog();
  }, []);
  function refreshPage() {
    window.location.reload();
  }
  return (
    <>
      <div style={{ padding: 20 }}>
        {filterBlog.map((item) => {
          return (
            <>
              <img
                src={item?.blog_img?.data.full_url}
                style={{ height: "50vh", width: "100%" }}
              />
              <div>
                <h1> {item?.title}</h1>
                <p style={{ fontSize: 12 }}>{item?.content}</p>
              </div>
              <div>
                <h2>{item?.sub_title} </h2>
                <p style={{ fontSize: 12 }}>{item?.sub_details}</p>
              </div>
              <div>
                <h3> {item?.summary}</h3>
                <p style={{ fontSize: 12 }}>{item?.full_blog}</p>
              </div>
            </>
          );
        })}
      </div>
    </>
  );
}

export default FullBlog;
