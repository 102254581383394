import { useState } from "react";

const Contact = () => {
  const [your_name, setyour_name] = useState("");
  const [your_email, setEmail] = useState("");
  const [your_number, setContact] = useState("");
  const [select_date, setDate] = useState("");
  const [your_message, setMessage] = useState("");
  const submitData = async (e) => {
    e.preventDefault();
    const res = await fetch("https://cms.maitretech.com/ncfa/items/form", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        your_name: your_name,
        your_email: your_email,
        your_number: your_number,
        select_date: select_date,
        your_message: your_message,
      }),
    });
    console.log("post response", res.json());
    if (res.status === 200 || 201) {
      alert("Form submitted successfully");

      setyour_name("");
      setEmail("");
      setContact("");
      setDate("");
      setMessage("");
    }
  };
  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-1 col-md-1"></div>
          <div className="mb-4 mr-3 col-lg-4 col-md-3 col-sm-12 addreshcss">
            <h1> 14, Landmark, Rohit Nagar, Phase-1, Bhopal-462039 (M.P.) </h1>
            <h1> Phone : 0755-2431251</h1>
            <h1>9425183888, 7691901888, 7691901666</h1>
          </div>

          <iframe
            title="map1"
            className="col-lg-6 col-md-7 col-sm-12 "
            src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d24673.38746138157!2d77.42366096420368!3d23.180381191732017!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xc8ecdd8d53e4c28f!2sNarmada%20College%20of%20Fine%20Arts!5e0!3m2!1sen!2sin!4v1624607963063!5m2!1sen!2sin"
            width="600"
            height="450"
            style={{ border: "0" }}
            allowfullscreen=""
            loading="lazy"
          ></iframe>
          {/* </div> */}
          <div className="col-lg-1 col-md-1"></div>
        </div>
        <br />
        <br />
        <br />
      </div>
      <div
        style={{
          margin: 0,
          background:
            "linear-gradient(90deg, rgba(6,36,0,1) 0%, rgba(139,205,29,1) 0%, rgba(0,86,255,1) 77%)",
        }}
      >
        <div
          style={{
            width: "45%",
            display: "flex",
            flexDirection: "column",
            borderRadius: 10,
            marginLeft: "27.5%",
          }}
        >
          <form
            autocomplete="off"
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              gap: 5,
            }}
            onSubmit={submitData}
          >
            <h1 style={{ textAlign: "center", color: "white", marginTop: 20 }}>
              Contact Us
            </h1>
            <label style={{ marginLeft: 10, color: "white", fontSize: 20 }}>
              Your Name
            </label>
            <input
              type="text"
              name="your_name"
              id="name"
              required=""
              placeholder="Your Name"
              value={your_name}
              onChange={(e) => setyour_name(e.target.value)}
              style={{
                padding: 8,
                marginLeft: 10,
                marginRight: 10,
                fontSize: 15,
              }}
            />

            <label style={{ marginLeft: 10, color: "white", fontSize: 20 }}>
              Your Email
            </label>
            <input
              type="email"
              name="your_email"
              id="email"
              required=""
              placeholder="Email"
              value={your_email}
              onChange={(e) => setEmail(e.target.value)}
              style={{
                padding: 8,
                marginLeft: 10,
                marginRight: 10,
                fontSize: 15,
              }}
            />
            <label
              style={{
                marginLeft: 10,
                color: "white",
                fontSize: 20,
                fontSize: 15,
              }}
            >
              Your Number
            </label>
            <input
              type="tel"
              name="number"
              id="number"
              pattern="[0-9]{10}"
              placeholder="Contact Number"
              required=""
              value={your_number}
              onChange={(e) => setContact(e.target.value)}
              style={{
                padding: 8,
                marginLeft: 10,
                marginRight: 10,
                fontSize: 15,
              }}
            />

            <span></span>
            <label style={{ marginLeft: 10, color: "white", fontSize: 20 }}>
              Select Date
            </label>
            <input
              type="date"
              name="Date"
              id="date"
              required=""
              value={select_date}
              onChange={(e) => setDate(e.target.value)}
              style={{
                padding: 8,
                marginLeft: 10,
                marginRight: 10,
                fontSize: 15,
              }}
            />
            <label style={{ marginLeft: 10, color: "white", fontSize: 20 }}>
              Your Message{" "}
            </label>
            <textarea
              class="form-control"
              name="message"
              id=""
              rows="3"
              required=""
              placeholder="Message..."
              value={your_message}
              onChange={(e) => setMessage(e.target.value)}
              style={{
                margin: 5,
                width: "98%",
                marginLeft: "1%",
                fontSize: 15,
              }}
            />

            <button
              type="submit"
              class="btn-grad"
              style={{
                padding: 8,
                width: "20%",
                marginLeft: 10,
                fontSize: 15,
                border: 0,
                borderRadius: 5,
              }}
            >
              Submit
            </button>
            <div class="Toastify"></div>
          </form>
        </div>
      </div>
    </>
  );
};
export default Contact;
