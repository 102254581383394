import React from "react";
import "./App.css";
import "bootstrap/dist/css/bootstrap.css";
import { Route, Switch } from "react-router-dom";
// import "../node_modules/bootstrap/dist/";
// import "bootstrap/dist/css"
import Header from "./Header";
import Footer from "./Footer";
import Home from "./Home";
import AboutUs from "./AboutUs";
import About from "./About";
import Courses from "./Courses";
import Facilities from "./Facilities";
import Gallery from "./Gallery";
import Contact from "./Contact";
import Blog from "./Blog";
import Videos from "./Videos";
import Photos from "./Photos";
import FullBlog from "./FullBlog";
const App = () => {
  return (
    <div>
      <Switch>
        <Route exact path="/">
          <Header />
          <Home />
          <Footer />
        </Route>
        <Route exact path="/AboutUs">
          <Header />
          <AboutUs />
          <Footer />
        </Route>
        <Route exact path="/Courses">
          <Header />
          <Courses />
          <Footer />
        </Route>
        <Route exact path="/Facilities">
          <Header />
          <Facilities />
          <Footer />
        </Route>
        <Route exact path="/Photos">
          <Header />
          <Gallery />
          <Footer />
        </Route>
        <Route exact path="/Contact">
          <Header />
          <Contact />
          <Footer />
        </Route>
        <Route exact path="/About">
          <Header />
          <About />
          <Footer />
        </Route>
        <Route exact path="/Videos">
          <Header />
          <Videos />
          <Footer />
        </Route>

        <Route exact path="/Blog">
          <Header />
          <Blog />
          <Footer />
        </Route>

        <Route exact path="/FullBlog/:id">
          <Header />
          <FullBlog />
          <Footer />
        </Route>
      </Switch>
    </div>
  );
};
export default App;
