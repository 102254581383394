import madum from "../src/Images/madum.jpg";

const AboutUs = () => {
  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-xl-1 col-lg-1 col-md-3  col-sm-3"></div>
        <div
          className="col-xl-3 col-lg-4 col-md-5  col-sm-6  aboutimg"
          style={{ textAlign: "center" }}
        >
          <img
            src={madum}
            id="inner-container"
            className="img-responsive"
            alt="no-img"
          />
          <h1
            style={{
              backgroundColor: "rgb(102, 146, 133)",
              color: "white",
              height: "50px",
              paddingTop: "10px",
            }}
          >
            डॉ. अपर्णा लाड
          </h1>
        </div>
        <div className="col-xl-1 col-lg-1 col-md-3  col-sm-3"></div>
        <div className="col-xl-5 col-lg-4 col-md-12  col-sm-12 mt-5">
          <h1 style={{ textAlign: "center" }} className="mb-5">
            Director Message
          </h1>
          <h1 className="mt-5 sas">
            दो शब्द..... <br />
            <br />
            मुझे यह अवगत कराते हुए हर्ष हो रहा है कि भोपाल नगरी जिसका सांस्कृतिक
            वैभव स्तुत्य रहा है हमने इसे और कलात्मक बनाने के उद्देश्य से यहाँ
            "माँ नर्मदा ललित कला महाविद्यालय" का प्रारंभ किया है। माँ नर्मदा
            ललित महाविद्यालय ग्वालियर के राजा मानसिंग तोमर संगीत एवं कला
            विश्वविद्यालय से संबंद्ध है। इस ललित कला महाविद्यालय में अध्ययनरत
            विद्यार्थियों के लिए सैद्धांतिक और व्यावहारिक शिक्षण प्रदान करने के
            लिए योग्य शिक्षक और शिक्षाविदों की व्यवस्था है। ललितकला महाविद्यालय
            संगीत और चित्रकला विषय के अध्यापन में प्रयुक्त किये जाने वाले साधन
            और उपकरणों से सुसज्जित है। हमारे ये शुभ संकल्प कला के शैक्षणिक
            मूल्यों को उस ऊंचाई तक ले जाने के लिए संकल्पित है जहाँ ज्ञान के
            स्त्रोत का कहीं ठहराव न हो और विकासान्मुख निरन्तरता ही सदैव बनी रहे।
            आप सबका सहयोग हमारे विकास को संबल प्रदान करेगा। आपकी ओर से
            मार्गदर्शन एवं आपसे शुभकामनाओं की प्राप्ति की अपेक्षा सहित।
            <br />
            <br />
            <span style={{ float: "right" }}>डॉ. अपर्णा लाड</span>
          </h1>
        </div>
        <div className="col-xl-1 col-lg-1"></div>
      </div>
      <br />
      <br />
      <br />
    </div>
  );
};
export default AboutUs;
