import { useState, useEffect } from "react";

const About = () => {
  const [aboutUs, setAboutUs] = useState([]);
  const getAboutUs = async () => {
    const res = await fetch(
      "https://cms.maitretech.com/ncfa/items/about_us?fields=*.*"
    );
    const data = await res.json();
    setAboutUs(data.data);
    console.log("about us", data);
  };
  useEffect(() => {
    getAboutUs();
  }, []);
  return (
    <div className="container-fluid">
      <div className="col-lg-1"></div>
      <div className="col-lg-10 mt-5 mb-5">
        {aboutUs?.map((item, index) => {
          return (
            <>
              <h1>{item?.title}</h1>
              <h1>{item?.paragraph}</h1>
            </>
          );
        })}
      </div>
      <div className="col-lg-1"></div>
    </div>
  );
};
export default About;
