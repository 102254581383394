import { useState, useEffect } from "react";

const Courses = () => {
  const [ourCoursesImg, setOurCoursesImg] = useState([]);
  const getOurCoursesImg = async () => {
    const res = await fetch(
      "https://cms.maitretech.com/ncfa/items/our_course_img?fields=*.*"
    );
    const data = await res.json();
    console.log("our work", data);
    setOurCoursesImg(data.data);
  };
  useEffect(() => {
    getOurCoursesImg();
  }, []);

  return (
    <>
      <div className="row imnb">
        <div className="col-xl-1"></div>
        {ourCoursesImg?.map((item, index) => {
          return (
            <div
              key={index}
              className="col-xl-3 col-lg-4 col-md-4 col-sm-12 mgn12 "
            >
              <div
                className="pac-col4 thumbnail "
                style={{ textAlign: "center" }}
              >
                <img
                  src={item?.course_img?.data?.full_url}
                  className="img-responsive display_none "
                  style={{ width: "100%", height: "180px" }}
                  alt="no-img"
                  id="display_none"
                />
                <h3 className="art_name_btn mt-3 d-flex justify-content-center">
                  <a href="#/">{item?.title}</a>
                </h3>
                <div
                  class="dropdown11 mb-3 d-flex justify-content-center "
                  style={{ width: "100%" }}
                >
                  <button type="button" className="btn btn-outline12">
                    <a href="#/ " className="btn-outline2">
                      Read More
                    </a>
                  </button>
                  <div class="dropdown-content1">
                    <p className="pt-3">B.F.A 4 yrs </p>
                    <p>BA (Fine Arts) 3 yrs</p>
                    <p>M.F.A. (Painting) 2 yrs</p>
                    <p>M.A. (Painting) regular 2 yrs</p>
                    <p>M.A. (Painting) private 2 yrs</p>
                  </div>
                </div>
              </div>
            </div>
          );
        })}

        <div className="col-xl-1"></div>
      </div>
    </>
  );
};
export default Courses;
