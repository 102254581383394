import React from "react";

const Footer = () => {
  return (
    <div className="container-fluid footer__container">
      <div className="row">
        <div className="col-sm-3 footer_li">
          <ul></ul>
        </div>
        <div className="col-sm-3 footer_li">
          <ul></ul>
        </div>
        <div className="col-sm-3 footer_li">
          <ul></ul>
        </div>
        <div className="col-sm-3 footer_li">
          <ul></ul>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-4 col-md-4 col-sm-6 footer_li">
          <ul>
            <b className="mr-5 pt-1">Connect With Us</b>
          </ul>
        </div>
        <div className=" col-lg-4 col-md-4  col-sm-12 mt-3 d-flex justify-content-center">
          <h3> 9425183888, 7691901888, 7691901666</h3>
        </div>
        <div className="col-lg-4 col-md-4 col-sm-12 mt-3 d-flex justify-content-center">
          <h3>ncfabhopal@gmail.com</h3>
        </div>
      </div>
    </div>
  );
};

export default Footer;
