const Facilities = () => {
  return (
    <div className="container-fluid">
      <div className="row mt-5 mb-5">
        <div className="col-xl-12  col-lg-12 col-md-12  col-sm-12 abouteus">
          <h1> Career Options</h1>
        </div>
      </div>
      <div className="row  mb-5">
        <div className="col-lg-1"></div>
        <div className="col-lg-3 col-md-4 col-sm-12 mt-3 Facilities1">
          <ul>
            <li>Teaching</li>
            <li>Graphing arts</li>
            <li>Television </li>
            <li>Magazines </li>
            <li>Theater production</li>
            <li> Product designing</li>
            <li>Publishing department </li>
            <li> Artist</li>
            <li> Advertising companies</li>
            {/* <li></li> */}
          </ul>
        </div>
        <div className="col-lg-4 col-md-4 col-sm-12 mt-3  Facilities1">
          <ul>
            <li>Fashion co-ordinator</li>
            <li>Sampling co-ordinator</li>
            <li>Design co-ordinator</li>
            <li>Faculty in a fashion institute </li>
            <li>Entrepreneur of garment business</li>
            <li>Boutique business</li>
            <li>Portrait in available light</li>
            <li>Portrait,modeling</li>
            <li>portfolio</li>
          </ul>
        </div>
        <div className="col-lg-3 col-md-4 col-sm-12 mt-3  Facilities1">
          <ul>
            <li> Product designing</li>
            <li>Publishing department </li>
            <li> Artist</li>
            <li> Advertising companies</li>
            <li>Fashion co-ordinator</li>
            <li>Sampling co-ordinator</li>
            <li>Design co-ordinator</li>
            <li>Faculty in a fashion institute </li>
            <li>Entrepreneur of garment business</li>
          </ul>
        </div>
        <div className="col-lg-1"></div>
      </div>
    </div>
  );
};
export default Facilities;
